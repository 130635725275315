import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { getRequest } from '~/api/requests'
import { fetchSegmentDetails, getSegmentUserList } from '~/api/segments'
import { fetchUsers } from '~/api/users'
import { ID } from '~/common.interface'
import { useStore } from '~/dataStore'
import {
  IUser,
  IUsersDTO,
  IUsersListMetadata,
  IUsersListQueryParams
} from '~/pages/Users/Users.interface'
import { NotificationType, showNotification } from '~/utils/Notification'

export enum ServerStateKeysEnum {
  Users = 'users',
  SegmentDetail = 'segmentDetail'
}

export const useUsersQueryState = () => {
  const cache = useQueryClient()
  return cache.getQueryState({ queryKey: ServerStateKeysEnum.Users })
}

export const useInvalidateSegmentDetails = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.SegmentDetail])
}

export const useFetchSegmentDetails = (segmentId?: ID) => {
  if (!segmentId) return null
  const {
    app: { currentApp }
  } = useStore()
  return useQuery([ServerStateKeysEnum.SegmentDetail, { segmentId }], () =>
    fetchSegmentDetails(currentApp.id, segmentId)
  )
}

export const useExportUsers = () => {
  const {
    app: { currentAdmin }
  } = useStore()
  return useMutation((data: { url: string }) => getRequest(data.url), {
    onSuccess: () => {
      showNotification(
        `Users exported to ${currentAdmin.email}`,
        NotificationType.SUCCESS
      )
    },
    onError: () => {
      showNotification(
        'Something went wrong exporting users',
        NotificationType.ERROR
      )
    }
  })
}

const useUsersList = (
  queryParams?: IUsersListQueryParams,
  segmentId?: ID | undefined
) => {
  const {
    app: { currentApp }
  } = useStore()
  return useInfiniteQuery<IUsersDTO>(
    [
      ServerStateKeysEnum.Users,
      {
        ...queryParams,
        segmentId
      }
    ],
    ({ pageParam = 1 }) => {
      if (segmentId) {
        return getSegmentUserList(currentApp.id, segmentId, {
          ...queryParams,
          page: pageParam
        })
      }

      return fetchUsers(currentApp.id, {
        ...queryParams,
        page: pageParam
      })
    },
    {
      keepPreviousData: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      select: (data: {
        pages: Array<{
          data: IUser[]
          metadata: IUsersListMetadata
        }>
        pageParams: Array<number | undefined>
      }) => ({
        pages: data.pages.flatMap((page) => page.data),
        metadata: {
          ...data.pages[0].metadata,
          totalUsers:
            data.pages[0].metadata.dataCount ??
            data.pages[0].metadata.totalUsers
        },
        pageParams: data.pageParams
      }),
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export default useUsersList
