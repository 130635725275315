export default function injestWebSDK() {
  const script = document.createElement('script')
  let key =
    process.env.NODE_CUSTOM_ENV === 'production'
      ? '77d9609ed3cb5e53f05ec7df8db7b4841b17227ed7d2c10e11500abbd07984a6'
      : '1a1f0572f55743a634963f7324352e1ae59855da7426e4ee83577810dc98470d'
  const webSDKMiddlewarePath =
    process.env.NODE_CUSTOM_ENV === 'production'
      ? 'https://web.pulsatehq.com/web-sdk.js'
      : 'https://web.furiousapi.com/web-sdk.js'

  if (window.location.hostname.includes('controlsealion')) {
    key = '4f4f94f2589504eb2dac86b4e71642bc92f90125e3c6e8c01c6dfdb276555dc8'
  }

  script.innerHTML = `
      (function (p,u,l,s,a,t,e) {
          var PulsateSDK = PulsateSDK || {};
          PulsateSDK.init = PulsateSDK.init || function () {
              (PulsateSDK.q = PulsateSDK.q || []).push(...arguments);
          };
          PulsateSDK.addUser = PulsateSDK.init
          PulsateSDK.endSession = PulsateSDK.init.bind(null, {c: 'endSession'});
          PulsateSDK.showFeed = function () { PulsateSDK.init({ c: 'showFeed', args: arguments });}
          PulsateSDK.getUnreadCount = function() { PulsateSDK.init({ c: 'getUnreadCount', args: arguments }); }
          PulsateSDK.setErrorHandler = function() { PulsateSDK.init({ c: 'setErrorHandler', args: arguments }); }
          PulsateSDK.saveEvent = function() { PulsateSDK.init({ c: 'saveEvent', args: arguments }); }

          p.PulsateSDK = PulsateSDK;
          PulsateSDK.init({key: a})
          var x = document.createElement(l);
          x.type = 'module';
          x.async = true;
          x.src = s;
          var y = u.getElementsByTagName(l)[0];
          y.parentNode.insertBefore(x, y);
      })(window, document, 'script', '${webSDKMiddlewarePath}', '${key}');`

  document.body.appendChild(script)
}
